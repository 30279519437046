import React from 'react';
import ReactDOM from 'react-dom';
import {
	Route,
	BrowserRouter as Router
} from 'react-router-dom';
import App from './App';
import {
	createBrowserHistory
} from "history";
import * as serviceWorker from './serviceWorker';
const history = createBrowserHistory();

console.log( 'app start' );
window.addEventListener('beforeinstallprompt', (e) => {
	console.log('first primpt check');
});

ReactDOM.render(
	<Router history={history}>
		<Route component={({location, history}) => <App history={history} location={location} />} />
	</Router>, document.getElementById( 'root' )
);

serviceWorker.register();

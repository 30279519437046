import React, {
	useState
} from 'react';
import TextField from '@material-ui/core/TextField';
import Header from 'components/Header';
import {
	auth
} from 'api';
import './style.scss';
import {
	Link
} from 'react-router-dom';
import PhoneNumberFormatted from "../Elements/PhoneNumber/Formatted";

function Login( props ) {
	const {
		setUser
	} = props;
	const [ username, setUsername ] = useState( '' );
	const [ password, setPassword ] = useState( '' );
	const [ response, setResponse ] = useState( null );
	const [ loginStep, setLoginStep ] = useState( 1 );

	const isValidNumber = function( number ) {
		// Remove all whitespace
		number.replace( /\s/g, '' );

		return ( 8 <= number.length );
	};

	const goToPasswordScreen = function() {
		if ( ! isValidNumber( username ) ) {
			setResponse( 'Skriv inn ditt mobilnummer, 8 siffer.' );
			return false;
		}

		setResponse( null );
		setLoginStep( 2 );
	};

	return (
		<div>
			<Header  navigation="menu">
				<div className="logo" />
			</Header>
			<div className="view login">
				<h1>Logg inn</h1>

				{1 === loginStep &&
				<div className="input-list">
					<p>
						Er du medlem i KNA? Skriv inn mobilnummeret ditt for å logge inn på Min side.
					</p>

					<div className="login-wrapper">
						<label>
							Mobilnummer
						</label>

						<div className="input-group">
							<TextField
								type="tel"
								variant="outlined"
								autoComplete="tel"
								onChange={e => setUsername(e.target.value)}
								value={username}
							/>

							<button onClick={goToPasswordScreen} className="primary inline">
								<span className="icon arrow-right white" />
								<span className="screen-reader-text">
									Til passord innføring
								</span>
							</button>
						</div>

						{ response &&
						<div className="login-response">{response}</div>
						}
					</div>

					<h2>
						Ikke medlem?
					</h2>

					<p>
						Bli medlem i KNA - den eldste bilorganisasjonen i Norge - og støtt bilens og bilistens sak!
					</p>

					<a href="https://kna.no/bli-medlem/" className="button primary inline">Bli medlem i KNA</a>
				</div>
				}

				{2 === loginStep &&
				<div className="input-list">
					<p>
						Vennligst oppgi passordet for <strong><PhoneNumberFormatted phoneNumber={username} /></strong>.
					</p>

					<label>
						Passord
					</label>

					<div className="input-group">
						<TextField
							type="password"
							variant="outlined"
							autoComplete="password"
							onChange={e => setPassword(e.target.value)}
							value={password}
						/>

						<button onClick={() => auth(username, password, setUser, setResponse)}
								className="block-link important plain">
								Logg inn
						</button>
					</div>


					{ response &&
					<div className="login-response">{response}</div>
					}

					<div className="focus-block">
						<Link to="/glemt-passord">Glemt passord</Link>, eller <Link to="#" onClick={ () => setLoginStep( 1 ) }>endre mobilnummer</Link>.
					</div>
				</div>
				}
			</div>
		</div>
	);
}
export default Login;

import React from 'react';

import './style.scss';
import {Link} from "react-router-dom";

function ExpiredPayment() {
	return (
		<div className="expired-payment-wrapper">
			<span className="icon bell"/>

			<br/>

			<strong>
				Heisann!
			</strong>

			<br/>
			<br/>

			Vi ser at betalingskortet ditt snart utløper.

			<br/>

			Vennligst oppdater betalingsinformasjonen din.

			<br/>
			<br/>

			<Link to="/" className="button primary inline">
				Oppdater betalingsmåte
			</Link>
		</div>
	)
}

export default ExpiredPayment;
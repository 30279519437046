import React, {useState, useEffect} from 'react';
import Header from "../Header";

import './style.scss';

const Loading = function( props ) {

    const [ longLoad, setLongLoad ] = useState( false );

    useEffect( () => {
        const longLoadTimer = setTimeout( () => {
            setLongLoad( true );
        }, 7000 );

        return () => {
            clearTimeout( longLoadTimer );
        }
    }, [] );

    return (
        <>
            <Header hideMenu={true}>
                <div className="logo" />
            </Header>

            <div className="view">
                <div className="loading-wrapper pulse">
                    <span className="app-loading-logo"/>
                    Laster...
                </div>

                {longLoad &&
                    <div className="loading-long-wait">
                        <p>
                            Det tar lengre tid å laste inn informasjonen om ditt medlemsskap enn forventet.
                        </p>

                        <p>
                            Dette kan være grunnet tekniske problemer, dersom du trenger tilgang på detaljer om ditt medlemskap, vennligst kontakt KNA direkte, vi beklager eventuelle ulemper dette måtte medføre.
                        </p>

                        <p>
                            Telefon <a href="tel:+4720604900">+47 21 60 49 00</a>
                            <br/>
                            E-post til <a href="mailto:post@kna.no">post@kna.no</a>
                        </p>
                    </div>
                }
            </div>
        </>
    );

}

export default Loading;
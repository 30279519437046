import React, {useEffect, useState} from 'react';
import Header from './Header';
import DashCardGrid from "./Elements/DashCardGrid";
import DashCard from "./Elements/DashCardGrid/DashCard";
import ExpiredPayment from "./ExpiredPayment";
import SupportCard from "./Elements/SupportCard";
import Footer from "./Footer";
import Notice from "./Elements/Notice";

function Dash( props ) {
	const {
		user,
		member,
		mainMembership,
		roadHelpPaid,
		hasPaidMembership,
		hasPendingPayment,
		isClassicInvoiceClient
	} = props;

	const [ paymentType, setPaymentType ] = useState( 'invoice' );

	/**
	 * ID numbers used to identify payment methods, as defined by Profundo:
	 *
	 * 0: giro/faktura
	 * 1: Avtalegiro, pre-agreement (rare occurrence)
	 * 5: Avtalegiro, active agreement
	 * 13: Vipps
	 * 14: Stripe (card)
	 */
	useEffect( () => {
		// Invoice payments are considered a fallback when nothing can be interpreted.
		if ( false === mainMembership || typeof mainMembership === "undefined" || typeof mainMembership.payment_method === "undefined" || typeof mainMembership.payment_method.id === "undefined" ) {
			setPaymentType( 'invoice' );
		} else {
			switch ( mainMembership.payment_method.id ) {
				case 14:
					setPaymentType( 'card' );
					break;
				case 13:
					setPaymentType( 'vipps' );
					break;
				case 5:
				case 1:
				case 0:
				default:
					setPaymentType( 'invoice' );
			}
		}
	}, [ mainMembership ] );

	if ( !user ) {
		return null;
	}

	const membershipExpiresSoon = ( expiryDate ) => {
		// How many days before a payment option expires should the warning be displayed?
		const expiryReminderDays = 7;

		// The time between the expiry needs to eb converted to milliseconds, the time format JavaScript works with.
		const timeComputation = 1000 * 60 * 60 * 24 * expiryReminderDays;

		const now = new Date();

		// The date we get from Profundo is not in an ISO format, so we split it and pass it back as ISO.
		expiryDate = expiryDate.split( '.' );
		const expiry = new Date( expiryDate[2] + '-' + expiryDate[1] + '-' + expiryDate[0] );

		return ( ( expiry.getTime() - now.getTime() ) < timeComputation );
	}

	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<div className="view">
				{ 'card' === paymentType && typeof mainMembership.payment_method !== "undefined" && null !== mainMembership.payment_method.vendor_data && membershipExpiresSoon( mainMembership.payment_method.vendor_data.card_expires ) &&
					<ExpiredPayment/>
				}

				{ hasPendingPayment &&
				<Notice
					heading="Betaling under behandling"
				>
					<p>
						Du har en betaling under behandling, og kan derfor ikke legge til et nytt familiemedlem før denne er ferdig behandlet.
						<br/>
						<br/>
						Dette kan i svært få tilfeller ta opp mot en time.
					</p>
				</Notice>
				}

				{ ( ! member || ( ! hasPaidMembership && isClassicInvoiceClient ) || hasPendingPayment ) &&
					<>
						<DashCardGrid>
							<DashCard
								icon="person"
								linkTo="/instillinger"
							>
								Min profil
							</DashCard>

							<DashCard
								icon="car"
								linkTo="/medlemskap"
							>
								Medlemskap
							</DashCard>
						</DashCardGrid>
					</>
				}

				{( member && hasPaidMembership) &&
					<>
						<DashCardGrid>
							<DashCard
								icon="card large"
								linkTo="/medlemskort"
							>
								Medlemskort
							</DashCard>

							<DashCard
								icon="sos large"
								linkTo="/berging"
								disabled={!roadHelpPaid}
							>
								Bilberging
							</DashCard>

							<DashCard
								icon="car large"
								linkTo="/medlemskap"
							>
								Medlemskap
							</DashCard>

							<DashCard
								icon="person large"
								linkTo="/instillinger"
							>
								Min profil
							</DashCard>

							<DashCard
								icon="creditcard large"
								linkTo="/betaling/kort"
							>
								Endre
								<br/>
								betalingsmåte
							</DashCard>
						</DashCardGrid>

						<SupportCard/>
					</>
				}
			</div>

			<Footer />

		</div>
	);
}
export default Dash;

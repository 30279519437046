import React from 'react';

import './style.scss';
import MenuItems from "../Menu/MenuItems";

function Footer() {
    return (
        <footer>
            <a className="to-kna" href="https://kna.no/">Gå tilbake til KNA.no</a>

            <hr />

            <div className="footer-logo" />

            <MenuItems />

        </footer>
    )
}

export default Footer;
import React, {
	useEffect,
	useState,
	useRef
} from 'react';
import {
	Route,
	Switch,
} from 'react-router-dom';
import {isIOS} from 'react-device-detect';

// Views
import Dash from 'components/Dash';
import Locate from './components/Locate';
import Card from './components/Card';
import Support from './components/Support';
import Settings from './components/Settings';
import Memberships from './components/Memberships';
import Login from './components/Login';
import Logout from './components/Logout';
import Register from './components/Register';
import Password from './components/Password';
import PayWall from './components/PayWall';
import ChangeCard from './components/ChangeCard';
import RoadHelp from './components/RoadHelp';
import Loading from "./components/Loading";

// Helpers
import {
	parseJwt,
	apiPost
} from 'api';


import './App.scss';
import ChangePaymentMethod from "./components/ChangePaymentMethod";
const localStorage = window.localStorage;

function App( props ) {
	const {
		location
	} = props;
	const [ name, setName ] = useState( [] );
	const [ memberships, setMemberships ] = useState( [] );
	const [ membershipsData, setMembershipsData ] = useState( [] );
	const [ member, setMember ] = useState( false );
	const [ customerID, setCustomerID ] = useState( false );
	const [ invoiceClient, setInvoiceClient ] = useState( false );
	const [ roadHelp, setRoadHelp ] = useState( false );
	const [ paid, setPaid ] = useState( false );
	const [ paymentPending, setPaymentPending ] = useState( false );
	const [ roadHelpPaid, setRoadHelpPaid ] = useState( false );
	const [ user, setUser ] = useState( false );
	const [ refresh, setRefresh ] = useState( Date.now() );
	const [ membershipDone, setMembershipDone ] = useState(0);
	const [ mainMembership, setMainMembership ] = useState(false);
	const [ installPrompt, setinstallPrompt ] = useState(false);
	const [ installPromptIos, setinstallPromptIos ] = useState(false);
	const overlayRef = useRef( null );

	useEffect( () => {
		if ( localStorage.getItem( 'kna-jwt' ) ) {
			setUser( parseJwt( localStorage.getItem( 'kna-jwt' ) ).sub );
		}
		window.addEventListener('beforeinstallprompt', (e) => {
		  // Prevent the mini-infobar from appearing on mobile
		  e.preventDefault();
		  // Update UI notify the user they can install the PWA
		  setinstallPrompt(e);
			setinstallPromptIos(false);
		});
		if(isIOS) {
			setinstallPromptIos(true);
		}
	}, [] );

	useEffect( () => {
		window.scrollTo( 0, 0 )
	}, [ location ] );
	useEffect( () => {
		if ( user ) {
			const data = {
				name_id: user
			}
			async function getName() {
				const response = await apiPost( 'profile/get-name', data );
				if ( response && response.status === 'ok' ) {
					setName( response.data.name );
				}
			}
			getName();
			async function getMembershipTypes() {
				const response = await apiPost( 'member/get-membership-types', data );
				if ( response && response.status === 'ok' ) {
					const parsed = {};
					for ( var i = 0; i < response.data.membership_types.length; i++ ) {
						const membershipData = response.data.membership_types[ i ];
						parsed[ membershipData.id ] = membershipData;
					}
					setMembershipsData( parsed );
				}
			}
			getMembershipTypes();
			async function getMembership() {
				const response = await apiPost( 'member/get-memberships', data );
				if ( response && response.status === 'ok' ) {
					setMemberships( response.data );
					for ( var i = 0; i < response.data.own.memberships.length; i++ ) {
						const membership = response.data.own.memberships[ i ];
						// This is a special membership, granting free roadside assistance for the user in question.
						if ( membership.type_id === 'BI-0-99' ) {
							setRoadHelp( true );
							setRoadHelpPaid( true );
						}

						if ( membership.type_id === 'BI-0-1' || membership.type_id === 'BI-0-9' ) {
							if ( membership.paid ) {
								setRoadHelpPaid( true );
							}
							setRoadHelp( true );
						} else {
							setMainMembership( membership );
							if ( membership.paid ) {
								setPaid( true );
								setCustomerID( membership.payment_method.customer_id );
							}
							setMember( true );
						}


						if ( typeof membership.payment_pending !== "undefined" && membership.payment_pending ) {
							setPaymentPending(membership.payment_pending);
						}

						// 0 = legacy paper oblat, 1 = invoice, 14 = card - Others may exist in between
						if ( 0 === membership.payment_method.id || 1 === membership.payment_method.id ) {
							setInvoiceClient( true );
						}
					}
					setMembershipDone(true);
				}
			}
			getMembership();
		}
	}, [user, refresh] );
	const maybeCloseOverlay = e => {
		if(installPrompt && e.target.contains(overlayRef.current)) {
			setinstallPrompt(false);
		}

		if(installPromptIos && e.target.contains(overlayRef.current)) {
			setinstallPromptIos(false);
		}
	}
	const installApp = () => {
		if(installPrompt) {
			console.log('trigger prompt');
			installPrompt.prompt();
			installPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the install prompt');
				} else {
					console.log('User dismissed the install prompt');
				}
			});
		}
	}
	return (
		<div className="app" onClick={maybeCloseOverlay}>
			{(installPrompt && !localStorage.getItem( 'kna-noprompt3' )) &&
				<div className="install-propmt-overlay" ref={overlayRef}>
					<div className="modal-inner">
						<button type="button" className="close-prompt" onClick={() => setinstallPrompt(false)} />
						<span className="app-icon" />
						<h2>Legg til KNA som en App</h2>
						<p>Om du legger til «KNA-Min side» på telefon eller nettbrett, så har du innloggingsinformasjonen lagret så tjenester som veihjelp og medlemskort er lett tilgjengelig om noe skulle skje på veien.</p>
						<p><button type="button" onClick={() => {installApp()}}>Installer KNA-appen</button><br /><button className="simple" type="button" onClick={() => {localStorage.setItem( 'kna-noprompt3', true );setinstallPrompt(false);}}>Ikke vis meg meldingen på nytt</button></p>
					</div>
				</div>
			}
			{(installPromptIos && !localStorage.getItem( 'kna-noprompt3' )) &&
				<div className="install-propmt-overlay ios-only" ref={overlayRef}>
					<div className="modal-inner">
						<button type="button" className="close-prompt" onClick={() => setinstallPromptIos(false)} />
						<h2>Legg til som App</h2>
						<p className="main-instruction">Innloggningsinformasjonen vil bli lagret så tjenester som veihjelp och medlemskort er lett tilgjengelig om noe skulle skje.</p>
						<div className="step-instructions">
							<h3>Sånn gjør du:</h3>
								<ol>
									<li><div className="step-example step-example-1" /><p>Trykk på <strong>Del-ikonet</strong> nederst på skjermen.</p></li>
									<li><div className="step-example step-example-2" /><p>Scroll ned og velg <strong>Legg til på hjem-skjermen</strong>.</p></li>
									<li><div className="step-example step-example-3" /><p>Trykk på <strong>Legg til</strong> øverst til høyre på skjermen.</p></li>
								</ol>
						</div>
						<p>Den nye web-appen din vises på neste tilgjengelige plass på startskjermen din</p>
						<p style={{marginBottom: '0px'}}><button className="simple" type="button" onClick={() => {localStorage.setItem( 'kna-noprompt3', true );setinstallPromptIos(false);}}>Ikke vis meg meldingen på nytt</button></p>
					</div>
				</div>
			}
			<Switch location={location}>
				<Route exact path={"/"} children={props => {
						if (user) {
							if(membershipDone) {
								if( paymentPending || !member || paid || invoiceClient ) {
									return <Dash member={member} mainMembership={mainMembership} setUser={setUser} user={user} roadHelpPaid={roadHelpPaid} hasPaidMembership={paid} hasPendingPayment={paymentPending} isClassicInvoiceClient={invoiceClient} />
								} else {
									return <PayWall membershipsData={membershipsData} memberships={memberships} setUser={setUser} user={name} />
								}
							} else {
								return <Loading/>
							}
						} else {
							return <Login setUser={setUser} />
						}
				}} />
				<Route exact path={"/logout"} children={ props=> <Logout setUser={setUser} />} />
				<Route exact path={"/registrer"} children={props => <Register setRefresh={setRefresh} />} />
				<Route exact path={"/glemt-passord"} children={props => <Password />} />
				<Route exact path={"/berging"} children={props => <Locate route={props} roadHelpPaid={roadHelpPaid} />} />
				<Route exact path={"/medlemskort"} children={props => <Card mainMembership={mainMembership} memberships={memberships} name={name} route={props} roadHelpPaid={roadHelpPaid} />} />
				<Route exact path={"/radgiving"} children={props => <Support memberships={memberships} route={props} />} />
				<Route exact path={"/instillinger"} children={props => <Settings setRefresh={setRefresh} user={user} name={name} route={props} />} />
				<Route exact path={"/medlemskap"} children={props => <Memberships hasPendingPayment={paymentPending} mainMembership={mainMembership} membershipsData={membershipsData} memberships={memberships} member={member} roadHelp={roadHelp} route={props} />} />
				<Route exact path={"/betaling"} children={props => <ChangePaymentMethod hasPendingPayment={paymentPending} />} />
				<Route exact path={"/betaling/kort"} children={props => {
					/* Using the same route, since Paywall adds card and ChangeCard changes the card. It doesn't make
					* sense to be able to get to the ChangeCard route before adding a card with Paywall */
					if ( paid && !paymentPending && customerID ) {
						return <ChangeCard user={user} member={member} memberships={memberships} customerID={customerID} />
					} else {
						return <PayWall hasPendingPayment={paymentPending} roadHelpPaid={roadHelpPaid} hasPaidMembership={paid} setUser={setUser} user={user} member={member} memberships={memberships} membershipsData={membershipsData} />
					}
				}} />
				<Route exact path={"/medlemskap/familiemedlem"} children={props => <Register user={user} name={name} route={props} setRefresh={setRefresh} />} />
				<Route exact path={"/medlemskap/veihjelp"} children={props => <RoadHelp memberships={memberships} user={user} route={props} setRefresh={setRefresh} />} />
			</Switch>
    </div>
	);
}

export default App;

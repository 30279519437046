import React from 'react';
import Header from 'components/Header';
import {
	Link,
} from 'react-router-dom';
import './style.scss';
import BackButton from "../Elements/BackButton";
import Notice from "../Elements/Notice";

function MembershipPaymentType( props ) {
	const { membership } = props;

	switch ( membership.payment_method.id ) {
		case 1:
			return (
				<span>Giro</span>
			);
		default:
			return null;
	}
}

function Membership( props ) {
	const {
		membership,
	} = props;
	switch ( membership.type_id ) {
		case 'ME-0':
		case 'ME-0-1':
			return (
				<div className="membership-type">
					<h4>Medlem</h4>
					<MembershipPaymentType membership={membership} />
					<span>Aktivt siden <span>{membership.since}</span></span>
				</div>
			)
		case 'BI-0':
		case 'BI-0-1':
			return (
				<div className="membership-type">
					<h4>Veihjelp</h4>
					<MembershipPaymentType membership={membership} />
					<span>Aktivt siden <span>{membership.since}</span></span>
				</div>
			)
		default:
		return (
			<div className="membership-type">
				<h4>{membership.type_name}</h4>
				<MembershipPaymentType membership={membership} />
				<span>Aktivt siden <span>{membership.since}</span></span>
			</div>
		)
	}
}

function FamilyMembership( props ) {
	const {
		membership,
	} = props;
	const render = [];
	for (var i = 0; i < membership.memberships.length; i++) {
		const current = membership.memberships[i];
		render.push(<Membership membership={current} />);
	}
	return(
		<fieldset>
			<legend>{membership.name}</legend>
			{render}
		</fieldset>
	);
}

function Memberships( props ) {
	const {
		memberships,
		membershipsData,
		member,
		roadHelp,
		mainMembership,
		hasPendingPayment
	} = props;
	if ( !membershipsData ) {
		return null;
	}
	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<BackButton/>

			<div className="view">
				<h1>Medlemskap</h1>

				<h2>Mine medlemskap</h2>
				{memberships.own && memberships.own.memberships.map((membership, key) =>
					<Membership membership={membership} />
				)}
				{!member &&
					<button className="block-link">Legg til medlemskap</button>
				}
				{(member && !roadHelp) &&
					<Link to="/medlemskap/veihjelp" className={"block-link"}>Legg til veihjelp</Link>
				}
				{(mainMembership.type_id === "ME-0-1") &&
					<div className="family-members">
						<h2>Familiemedlemskap</h2>
						{(memberships.family) && memberships.family.map(membership => <FamilyMembership membership={membership} />)}
					</div>
				}

				{ hasPendingPayment &&
				<Notice
					heading="Betaling under behandling"
				>
					<p>
						Du har en betaling under behandling, og kan derfor ikke legge til et nytt familiemedlem før denne er ferdig behandlet.
						<br/>
						<br/>
						Dette kan i svært få tilfeller ta opp mot en time.
					</p>
				</Notice>
				}

				{!hasPendingPayment &&
				<Link to="/medlemskap/familiemedlem" className={"button inline primary " + (member ? '' : ' disabled')}>
					Legg til et familiemedlemskap
				</Link>
				}
			</div>
		</div>
	);
}
export default Memberships;

const apiUrl = 'https://kna.no/profundo-api/api.php';
//const apiUrl = 'https://9d93cba4.ngrok.io/profundo-api/api.php';
const localStorage = window.localStorage;

function auth( username, password, setUser, setResponse ) {
	const method = 'auth';
	setResponse( false );
	fetch( `${apiUrl}?method=${method}&username=${username}&password=${password}`, {
		method: 'POST',
	} ).then( response => response.json() ).then( response => {
		if ( response.status ) {
			console.log(response.status);
			switch (response.status) {
				case 'invalid_credentials':
					setResponse( 'Feil telefonnummer eller passord' );
				break;
				default:
					setResponse( response.status + ': ' + response.message );
			}
			switch ( response.status ) {
				case 'success':
					if ( response.token ) {
						localStorage.setItem( 'kna-jwt', response.token );
						setUser( parseJwt( response.token ).sub );
					}
					break;
				default:
					setUser( false );
			}
		}
	} );
}

function parseJwt( token ) {
	var base64Url = token.split( '.' )[ 1 ];
	var base64 = base64Url.replace( /-/g, '+' ).replace( /_/g, '/' );
	var jsonPayload = decodeURIComponent( atob( base64 ).split( '' ).map( function( c ) {
		return '%' + ( '00' + c.charCodeAt( 0 ).toString( 16 ) ).slice( -2 );
	} ).join( '' ) );

	return JSON.parse( jsonPayload );
};

function reAuth() {
	return true;
}

function logout( setUser ) {
	localStorage.removeItem( 'kna-jwt' );
	setUser( false );
}

function apiPost( method, data ) {
	const jwt = localStorage.getItem( 'kna-jwt' );
	const JSONdata = JSON.stringify( data );
	return new Promise( resolve => {
		if ( jwt ) {
			fetch( `${apiUrl}?method=${method}&data=${JSONdata}`, {
				method: 'POST',
				headers: {
					'Authorization': 'bearer ' + jwt,
				}
			} ).then( response => response.json() ).then( response => {
				resolve( response );
			} );
		} else {
			fetch( `${apiUrl}?method=${method}&data=${JSONdata}`, {
				method: 'POST',
			} ).then( response => response.json() ).then( response => {
				resolve( response );
			} );
		}
	} );
}

export {
	auth,
	reAuth,
	logout,
	apiPost,
	parseJwt
};

import React, {
	useState,
	useEffect
} from 'react';
import './style.scss';
import Header from 'components/Header';
import BackButton from "../Elements/BackButton";

function Card( props ) {
	const {
		name,
		memberships,
		roadHelpPaid,
		mainMembership
	} = props;
	const [ orientationY, setOrientationY ] = useState( 0 );
	const [ orientationX, setOrientationX ] = useState( 0 );
	useEffect( () => {
		window.addEventListener( "deviceorientation", e => {
			if ( e.beta && e.beta < 80 ) {
				setOrientationY( e.gamma );
				setOrientationX( e.beta - 20 );
			}
		}, true );
	}, [] );
	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<BackButton/>

			<div className="prevent-overflow" >
				<div className="member-card" style={{transform:'perspective(1000px) rotateX('+ orientationX / 4+'deg) rotateY('+-orientationY / 2+'deg) scale3d(1,1,1)'}}>
					<div className="card-glare" style={{transform:'translateX('+orientationY * 5+'px) rotate(10deg)'}} />
					<div className="card-header">
						<div className="stamp" />
						<h2>Medlemskort</h2>
						{roadHelpPaid &&
							<div className="badges">
								<div className="badge road-help" >Med veihjelp</div>
							</div>
						}
					</div>

					<div className="card-info">
						<div>
							<h3 className="label">Navn</h3>
							<span>{name.first_name} {name.last_name}</span>
						</div>
						{memberships.own &&
							<div>
								<h3 className="label">Medlemsnummer</h3>
								<span>{memberships.own.name_id}</span>
							</div>
						}

						{'' !== mainMembership.valid_until &&
						<div>
							<h3 className="label">Gyldig til</h3>
							<span>{mainMembership.valid_until}</span>
						</div>
						}

						{mainMembership.local_team_name &&
							<div>
								<h3 className="label">Avdeling</h3>
								<span>{mainMembership.local_team_name}</span>
							</div>
						}
					</div>

					<div className="card-footer" />
				</div>
			</div>
			<p className="card-desc"><a href="mailto:post@kna.no">Kontakt KNA</a> for å få tilsendt fysisk medlemskort.</p>
		</div>
	);
}
export default Card;
